import React from "react";
import "./GDHeader.css";

const header = () => {
  return (
    <div>
      <div className="gdheader-top">
        <div className="gdheader">
        <div className="gdheader-logo">
          <img src="./GraciaDowntownPhotos/Header/logo.png" alt="" />
        </div>
          <div className="gdheader-content">
            <h3>Gracia Downtown, A Lifestyle Beyond Luxury</h3>
            <p>
              Residential Apartments Located in the Heart of Bahria Town, Lahore.
            </p>
          </div>
        </div>
      </div>
      <div className="gdheadermobileview">
        <div className="gdheadermobileview-img">
          <img src="./GraciaDowntownPhotos/Header/headermb.jpg" alt="building" />
        </div>
        <div className="gdheadermobileview-content">
            <h3>Gracia Downtown, A Lifestyle Beyond Luxury</h3>
            <p>
              Residential Apartments Located In The Heart of Bahria Town, Lahore.
            </p>
          </div>
      </div>
    </div>
  );
};

export default header;
