import './Services.css'

const Services = () => {
    return (
        <div>
            <div className="gdservices">
                <div className="gdservices-heading">
                    <h3>AMENITIES AND SERVICES</h3>
                </div>
                <div className="gdservices-cards">
                    <div className="gdservices-card">
                        <img src="./GraciaDowntownPhotos/Services/1.png" alt="services" />
                        <hr />
                        <p>Swiss Cafe</p>
                    </div>
                    <div className="gdservices-card">
                        <img src="./GraciaDowntownPhotos/Services/2.png" alt="services" />
                        <hr />
                        <p>BUY-ME</p>
                    </div>
                    <div className="gdservices-card">
                        <img src="./GraciaDowntownPhotos/Services/3.png" alt="services" />
                        <hr />
                        <p>Eventives</p>
                    </div>
                    <div className="gdservices-card">
                        <img src="./GraciaDowntownPhotos/Services/4.png" alt="services" />
                        <hr />
                        <p>Inspirations Gym</p>
                    </div>
                    <div className="gdservices-card">
                        <img src="./GraciaDowntownPhotos/Services/5.png" alt="services" />
                        <hr />
                        <p>Inspirations Pool</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services