import NavBar from '../Components/GraciaOrchird/Navbar/Navbar'
import GOHeader from '../Components/GraciaOrchird/GOHeader/GOHeader'

const GraciaOrchird = () => {
  return (
    <div>
      <NavBar />
      <GOHeader />

    </div>
  )
}

export default GraciaOrchird

// <Link to='/' className=' text-center text-[20px] font-medium bg-black text-gray-50 rounded py-2 px-4'>Go To HOME</Link>