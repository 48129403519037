import './GOHeader.css'

const GOHeader = () => {
    return (
        <div>
            <div className='gracia-orchird'>
                <h3 >COMING SOON...</h3>
                <p >We are going to launch our website very soon.</p>
                <h4>Stay Tuned</h4>
            </div>
        </div>
    )
}

export default GOHeader