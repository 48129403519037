import React from 'react'
import Navbar from "../Components/AboutUs/Navbar/Navbar"
import AboutHeader from "../Components/AboutUs/AboutHeader/AboutHeader"
import About from '../Components/AboutUs/About/AboutUsAbout'
import CeoMessage from '../Components/AboutUs/CeoMessage/CeoMessage'
// import Amenities from '../Components/AboutUs/Amenities/Amenities'
import OurTeam from '../Components/Home/OurTeam/OurTeam'
import AffiliateTeam from '../Components/AboutUs/AffiliateTeam/AffiliateTeam'
import Surrounding from '../Components/AboutUs/Surrounding/Surrounding'
import InternationalPartners from '../Components/AboutUs/InternationalPartners/InternationalPartners'
import OurPartners from '../Components/AboutUs/OurPartners/OurPartners'
import Footer from '../Components/AboutUs/Footer/Footer'


const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <AboutHeader />
      <About />
      <CeoMessage />
      <OurTeam />
      <AffiliateTeam />
      <Surrounding />
      <InternationalPartners />
      <OurPartners />
      <Footer />
      
      
      
      
      </div>
    )
  }
  
  export default AboutUs
  // <Amenities />