import './Header.css'
import Navbar from "../../Home/Navbar/Navbar"
import { NavLink } from 'react-router-dom'

const Header = () => {
    return (
        <div>
            <div className="homeheader">
            <div className='head'>
                <Navbar />
                <hr />
                <div className='header'>
                    <div className='header-content'>
                        <h1>where every <span>Project</span> is a masterpiece</h1>
                        <div>
                            <p>At GRACIA, we pride ourselves on our extensive background in project management, a cornerstone that has propelled us to the forefront of the industry.
                            </p>
                            <h3>
                                An Extraordinary Living Experience
                            </h3>
                        </div>
                    </div>
                    <div  className='header-img'>
                        <NavLink to='/GraciaDowntown'><img src="./HomePhotos/Header/1.jpg" alt="headr-pic" /></NavLink>
                        <NavLink to='/GraciaPark'><img src="./HomePhotos/Header/2.jpg" alt="headr-pic" className='header-2img'/></NavLink>
                        <NavLink to='/GraciaOrchird'><img src="./HomePhotos/Header/3.jpg" alt="headr-pic" /></NavLink>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Header