import "./EHeader.css"

const EHeader = () => {
  return (
    <div>
      <div className='e-header'>
        <div className="e-header-left">
          <h2>GRACIA EVENTS</h2>
          <p>Stay tuned to discover the latest happenings and exciting events.</p>
        </div>
        <div className="e-header-right">
          <img src="./EventsPhotos/EHeader/2.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default EHeader