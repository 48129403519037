import ReactPlayer from 'react-player'
import './SiteVisit.css'

const SiteVisit = () => {
  return (
    <div>

      <div className='sitevisit'>
        <div className='sitevisit-img'>
          <div className='sitevisit-1img'>
            <img src="./EventsPhotos/Gallery/site1.jpg" alt="" />
            <img src="./EventsPhotos/Gallery/site2.jpg" alt="" />
            <img src="./EventsPhotos/Gallery/site3.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className='sitevisit-vid'>
        <ReactPlayer url='./EventsPhotos/Gallery/site.mp4' controls='true' />
      </div>
    </div>
  )
}

export default SiteVisit
// <div className='sitevisit-2img'>
//   <img src="./EventsPhotos/Gallery/site4.jpg" alt="" />
// </div>