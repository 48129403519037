import React from 'react'
import Navbar from '../Components/GraciaDowntown/Navbar/Navbar'
import GDHeader from '../Components/GraciaDowntown/GDHeader/GDHeader'
import Parkresidency from '../Components/GraciaDowntown/ParkResidency/Parkresidency'
import International from '../Components/GraciaDowntown/International/International'
import Premiumresidences from "../Components/GraciaDowntown/PremiumResidences/Premiumresidences"
// import ResidencePlan from "../Components/GraciaDowntown/ResidencePlan/ResidencePlan"
import Services from '../Components/GraciaDowntown/Services/Services'
import Amenities from "../Components/GraciaDowntown/Amenities/Amenities"
import Surrounding from '../Components/GraciaDowntown/Surrounding/Surrounding'
import Footer from '../Components/GraciaDowntown/Footer/Footer'


const GraciaDowntown = () => {
  return (
    <div>
      <Navbar />
      <GDHeader />
      <Parkresidency />
      <International />
      <Premiumresidences />
      <Services />
      <Amenities />
      <Surrounding />
      <Footer />
      </div>
    )
  }
  
  export default GraciaDowntown
  // <ResidencePlan />