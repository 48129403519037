import React from 'react'
import Header from '../Components/Home/Header/Header'
import HomeAbout from "../Components/Home/HomeAbout/HomeAbout"
import CeoMessage from '../Components/Home/CeoMessage/CeoMessage'
import Vision from '../Components/Home/Vision/Vision'
import WhyChoose from '../Components/Home/WhyChoose/WhyChoose'
// import Mission from '../Components/Home/Mission/Mission'
import OurTeam from '../Components/Home/OurTeam/OurTeam'
import AffiliateTeam from '../Components/Home/AffiliateTeam/AffiliateTeam'
import InternationalPartners from '../Components/Home/InternationalPartners/InternationalPartners'
import OurPartners from '../Components/Home/OurPartners/OurPartners'
import VisitUs from '../Components/Home/VisitUs/VisitUs'
import Footer from '../Components/Home/Footer/Footer'


const Home = () => {
  return (
    <div>
      <Header/>
      <HomeAbout/>
      <CeoMessage />
      <Vision/>
      <WhyChoose />
      <OurTeam />
      <AffiliateTeam />
      <InternationalPartners />
      <OurPartners />
      <VisitUs />
      <Footer />
    </div>
  )
}

export default Home
// <Mission />