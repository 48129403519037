import React from 'react'
import Navbar from "../Components/Events/Navbar/Navbar"
import EHeader from '../Components/Events/EHeader/EHeader'
// import LatestEvent from '../Components/Events/LatestEvent/LatestEvent'
import Gallery from '../Components/Events/Gallery/Gallery'
import Footer from "../Components/Events/Footer/Footer"



const Events = () => {
  return (
    <div>
        <Navbar />
        <EHeader />
        <Gallery />
        <Footer />
        </div>
      )
    }
    
export default Events
    // <LatestEvent />