import ReactPlayer from 'react-player'
import './OfficeVisit.css'

const OfficeVisit = () => {
  return (
    <div>
      
      <div className='officevisit'>
        <div className='officevisit-1img'>
          <div className='officevisit-1img-left'>
            <img src="./EventsPhotos/Gallery/office1.jpg" alt="" />
            <img src="./EventsPhotos/Gallery/office2.jpg" alt="" />
            <img src="./EventsPhotos/Gallery/office3.jpg" alt="" />
            <img src="./EventsPhotos/Gallery/office4.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className='officevisit-vid'>
        <ReactPlayer url='./EventsPhotos/Gallery/office.mp4' controls='true' />
      </div>
    </div>
  )
}
        
export default OfficeVisit
        
        
        
        
        
        // <div className='officevisit-1img-right'>
        // </div>
        
        // <div className='officevisit-2img'>
        //   <img src="./EventsPhotos/Gallery/office5.jpg" alt="" />
        // </div>