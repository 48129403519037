import './GPResidencePlan.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'


const ResidencePlan = () => {

  const [index, setIndex] = useState(0);

  const plans = [
    { type: 'BED ROOM', beds: 1, bathrooms: 1, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/1bed-02.jpg' },
    { type: '2 BED ROOM', beds: 2, bathrooms: 2, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/2bed-02.jpg' },
    { type: 'PENTHOUSE', beds: 3, bathrooms: 3, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/penthouse.jpg' },
    // { type: 'BED ROOM', beds: 1, bathrooms: 1, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/4.jpg' },
    // { type: '2 BED ROOM', beds: 2, bathrooms: 2, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/5.jpg' },
    // { type: 'PENTHOUSE', beds: 3, bathrooms: 3, KITCHENETTE: 1, lounge: 1,  image: './GraciaDowntownPhotos/ResidencePlan/6.jpg' }
  ]
  const handleLeftArrowClick = () => {
    if (index < plans.length - 1) {
      setIndex(index + 1);
    }
  };

  const handleRightArrowClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };


  return (
    <div>
      <div className="gpresidence">
        <div className="gpresi">
          <div className='gpresidenceplan'>
            <div className='gpresidenceplan-heading'>
              <h3>Residence Plan</h3>
            </div>
            <div className='gpresidenceplan-card'>
              <div className='residenceplan-card-left'>
                <div className='residenceplan-card-left-top'>
                  <h4>{plans[index].type}</h4>
                  <div>
                    <button onClick={handleRightArrowClick} disabled={index === 0} >
                      <FontAwesomeIcon icon={faArrowLeft} className='residenceplan-card-left-top-arrows' />
                    </button>
                    <button onClick={handleLeftArrowClick} disabled={index === plans.length - 1}>
                      <FontAwesomeIcon icon={faArrowRight} className='residenceplan-card-left-top-arrows' />
                    </button>
                  </div>
                </div>
                <div className='residenceplan-card-left-mid'>
                  <div className='residenceplan-card-left-mid-content'>
                    <p>BEDROOM </p>
                    <p>{plans[index].beds}</p>
                  </div>
                  <div className='residenceplan-card-left-mid-content'>
                    <p>bathrooms</p>
                    <p>{plans[index].bathrooms}</p>
                  </div>
                  <div className='residenceplan-card-left-mid-content'>
                    <p>KITCHENETTE</p>
                    <p>{plans[index].KITCHENETTE}</p>
                  </div>
                  <div className='residenceplan-card-left-mid-content'>
                    <p>LOUNGE</p>
                    <p>{plans[index].lounge}</p>
                  </div>
                </div>
                <div className='residenceplan-card-left-bottom'>
                  <p>
                    We take pride in offering luxurious living spaces where you can truly unwind and enjoy your time.
                  </p>
                </div>
              </div>
              <div className='residenceplan-img'>
                <img src={plans[index].image} alt={plans[index].type} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResidencePlan





// {
//   const [index, setIndex] = useState(0);
//   const texts = ["Text 1", "Text 2", "Text 3"]; // Array of text data
//   const images = ["./GraciaDowntownPhotos/ResidencePlan/1.jpg",
//     "./GraciaDowntownPhotos/ResidencePlan/2.jpg", "./GraciaDowntownPhotos/ResidencePlan/3.jpg", "./GraciaDowntownPhotos/ResidencePlan/4.jpg",
//     "./GraciaDowntownPhotos/ResidencePlan/5.jpg", "./GraciaDowntownPhotos/ResidencePlan/6.jpg", ""]; // Array of image URLs

//   // Function to handle click event for the left arrow
//   const handleLeftArrowClick = () => {
//     // Calculate the new index for previous text and image
//     const newIndex = (index - 1 + texts.length) % texts.length;
//     setIndex(newIndex);
//   };

//   // Function to handle click event for the right arrow
//   const handleRightArrowClick = () => {
//     // Calculate the new index for next text and image
//     const newIndex = (index + 1) % texts.length;
//     setIndex(newIndex);
//   };
// }