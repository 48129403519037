import React from "react";
import './Surrounding.css'

const Surrounding = () => {
  return (
    <div>
      <div className="aboutsurrounding">
        <video src="./AboutPhotos/Surrounding/surrounding.mp4" controls></video>
      </div>
    </div>
  );
};

export default Surrounding;
