import { useState } from 'react';
import './WhyChoose.css'


const WhyChoose = () => {
    const [currentImage, setCurrentImage] = useState("./HomePhotos/WhyChoose/1.jpg");

  const handleImageChange = (imagePath) => {
    setCurrentImage(imagePath);
  };    
    return (
        <div>
            <div className="whychoose">
                <div className="whychoose-top">
                    <div className="whychoose-heding">
                        <h2>Why Choose US?</h2>
                        <hr />
                    </div>
                    <h3>Tech Savvy Residences Control Your Home Digitally</h3>
                </div>
                <div className='whychoose-bottom'>
      <div className='whychoose-bottom-left'>
        <div className='whychoose-bottom-left-table' onClick={() => handleImageChange("./HomePhotos/WhyChoose/1.jpg")}>
          <p>01</p>
          <h4>State of Art System</h4>
        </div>
        <div className='whychoose-bottom-left-table' onClick={() => handleImageChange("./HomePhotos/WhyChoose/2.jpg")}>
          <p>02</p>
          <h4>Focus on Delivering</h4>
        </div>
        <div className='whychoose-bottom-left-table' onClick={() => handleImageChange("./HomePhotos/WhyChoose/3.jpg")}>
          <p>03</p>
          <h4>Client Centric</h4>
        </div>
        <div className='whychoose-bottom-left-table' onClick={() => handleImageChange("./HomePhotos/WhyChoose/4.jpg")}>
          <p>04</p>
          <h4>Smart Building</h4>
        </div>
        <div className='whychoose-bottom-left-table' onClick={() => handleImageChange("./HomePhotos/WhyChoose/5.jpg")}>
          <p>05</p>
          <h4>Top Class Project Management</h4>
        </div>
      </div>
      <div className="whychoose-img">
        <img src={currentImage} alt="Why Choose Us" />
      </div>
    </div>
            </div>

        </div>
    )
}

export default WhyChoose