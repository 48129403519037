import './Vision.css'

const Vision = () => {
  return (
    <div>
        <div className='vision'>
            <div className='vision-heading'>
                <h2>OUR VISION</h2>
                <hr />
            </div>
            <div className='vision-content'>
                <p>
                GRACIA aims to lead in delivering top project management, unparalleled services, and a luxurious lifestyle. Defining a new era of visionary living by customer centric, groundbreaking ideas, exceptional development standards and dedicated efforts of a motivated team.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Vision