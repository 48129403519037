import './App.css'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import Home from './Pages/Home';
import GraciaPark from './Pages/GraciaPark';
import GraciaDowntown from './Pages/GraciaDowntown';
import GraciaOrchird from './Pages/GraciaOrchird';
import ContactUs from './Pages/ContactUs'
import AboutUs from './Pages/AboutUs';
import Events from './Pages/Events';
import { useEffect } from 'react';
import ReactGA from "react-ga4";

ReactGA.initialize("G-8FRVM3PXFP");

ReactGA.send({ 
  hitType: "pageview",
   page: "/my-path",
    title: "Custom Title" 
  });

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/GraciaPark' element={<GraciaPark />} />
        <Route path='/GraciaDowntown' element={<GraciaDowntown />} />
        <Route path='/GraciaOrchird' element={<GraciaOrchird />} />
        <Route path='/ContactUs' element={<ContactUs />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/Events' element={<Events />} />

      </Routes>
    </div>
  );
}

export default App;










// <Routes>
//

//       </Routes>