import './PartnerContract.css'
import React from 'react'
import ReactPlayer from 'react-player/lazy'

// <video src="./EventsPhotos/Gallery/partners.mp4" controls autoPlay='true'></video>
const PartnerContract = () => {
    return (
        <div>

            <div className='partnercontract'>
                <div className='partnercontract-img'>
                    <div className='partnercontract-1img'>
                        <img src="./EventsPhotos/Gallery/dubai1.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai2.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai3.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai6.jpg" alt="" />
                    </div>
                    <div className='partnercontract-2img'>
                        <img src="./EventsPhotos/Gallery/dubai4.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai5.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai7.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai8.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/dubai9.jpg" alt="" />
                    </div>
                    <img src="./EventsPhotos/Gallery/dubai7.jpg" alt="" className='partnercontract-seprtimg'/>
                </div>
            </div>
            <div className='partnercontract-vid'>
                <ReactPlayer url='./EventsPhotos/Gallery/partners.mp4' controls='true' />
            </div>
        </div>
    )
}

export default PartnerContract
// <div className='partnercontract-3img'>
// </div>