import './AffiliateTeam.css'

const AffiliateTeam = () => {
    return (
        <div>
            <div className="contact-ouraffiliateteam">
                <div className='ouraffiliateteam-head'>
                    <div className='ouraffiliateteam-heading'>
                        <h2>affiliate team</h2>
                        <hr />
                    </div>
                </div>
                <div className='ouraffiliateteam-bottom'>
                    <div className='ouraffiliateteam-bottom-card'>
                        <div className='ouraffiliateteam-bottom-card-img'>
                            <img src="./AboutPhotos/AffiliateTeam/1.png" alt="cards" />
                        </div>
                        <div className='ouraffiliateteam-bottom-card-data'>
                            <h4>sidra hussain</h4>
                            <p>( hilighter )</p>
                        </div>
                    </div>
                    <div className='ouraffiliateteam-bottom-card'>
                        <div className='ouraffiliateteam-bottom-card-img'>
                            <img src="./AboutPhotos/AffiliateTeam/2.png" alt="cards" />
                        </div>
                        <div className='ouraffiliateteam-bottom-card-data'>
                            <h4>naveed</h4>
                            <p>( avenue marketing )</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateTeam