import "./CeoMessage.css";

const CeoMessage = () => {
  return (
    <div>
      <div className="hceomessage">
        <div className="hceomessage-img">
          <img src="/AboutPhotos/CeoMessage/CeoMessage.jpg" alt="" />
        </div>
        <div className="hceomessage-message">
          <h3>CEO's MESSAGE</h3>
          <h4>“We believe in the best!”</h4>
          <img src="/AboutPhotos/CeoMessage/signature.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CeoMessage;


// <h5>Best Regard</h5>
// <p>Nasrullah Tahir Dogar</p>