import './VisitUs.css'

const VisitUs = () => {
    return (
        <div>
            <div className="visitus">
                <div className='visitus-heading'>
                    <h3>visit us</h3>
                    <hr />
                </div>
                <div className='visitus-address'>
                <p>45 Q, Quaid commercial, bahria town, lahore.</p>
                </div>
            </div>
            <div className="visitus-img">
                <img src="./HomePhotos/VisitUs/1.jpg" alt="" />
            </div>
        </div>
    )
}

export default VisitUs