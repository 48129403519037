import './GetInTouch.css'
import { Link } from 'react-router-dom'

const GetInTouch = () => {
  return (
    <div>
      <div className="getin">
        <div className='getintouch'>
          <div className="getintouch-left">
            <div className="getintouch-left-heading">
              <h3>Get In Touch</h3>
              <hr />
            </div>
            <p>We're here to help with any questions you may have. Feel free to reach out to us!</p>
            <div className="getintouch-left-content">
              <div className="getintouch-left-content-adress">
                <h5>VISIT US</h5>
                <p>The park Residences, <br />
                Egyptian Block, Bahria Town, <br />
                Lahore, Pakistan</p>
              </div>
              <div className="getintouch-left-content-call">
                <h5>CALL US</h5>
                <p>UAN: 0311 1100 564</p>
              </div>
              <div className="getintouch-left-content-email">
                <h5>EMAIL US</h5>
                <Link to="mailto:info@gracia.pk">info@gracia.pk</Link>
              </div>
              <div className="getintouch-left-content-follow">
                <h5>FOLLOW US</h5>
                <div className='getintouch-left-content-follow-links'>
                  <Link to='https://www.facebook.com/thegraciapk' target='_blank' className='getintouch-left-content-follow-links-fb'>
                    <img src="./ContactPhotos/GetIn/fb.png" alt="" className='getintouch-left-content-email-links-img-fb' />
                  </Link>
                  <Link to='https://www.instagram.com/the.graciapk/' target='_blank' className='getintouch-left-content-follow-links-insta'>
                    <img src="./ContactPhotos/GetIn/insta.png" alt="" className='getintouch-left-content-email-links-img-insta' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="getintouch-right">
            <form action="" className="getintouch-right-form">
              <div className='getintouch-right-form'>
                <label htmlFor="name">Name <sup>*</sup></label>
                <div>
                  <input type="text" name="" id="name" placeholder='First Name' />
                  <input type="text" name="" id="" placeholder='Last Name' />
                </div>
              </div>
              <label htmlFor="mail">Email <sup>*</sup></label>
              <input type="text" name="" id="mail" placeholder='xyz@gmail.com' />
              <label htmlFor="msg">Comment or Message <sup>*</sup></label>
              <textarea name="" id="msg" cols="30" rows="5" style={{ lineHeight: "20px" }}></textarea>
              <input type="submit" value="Send Message" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetInTouch