import './AboutUsAbout.css'

const About = () => {
  return (
    <div>
      <div className='aboutus-about'>
        <div className='aboutus-heading'>
          <h2>ABOUT US</h2>
          <hr />
        </div>
        <h3>welcome to gracia</h3>
        <h4>where every milestone is a step towards a luxury living.</h4>
        <div className='aboutus-body'>
          <p>At GRACIA, we pride ourselves on our extensive background in project management, a cornerstone that has propelled us to the forefront of the industry. With years of dedicated service and a diverse portfolio, we have successfully navigated the intricacies of townhome construction, commercial projects, and land provision, leaving an indelible mark on the landscapes we touch.</p>
          <p>Our journey is defined by perfection and a passion for crafting spaces that transcend the ordinary. Whether it's the meticulous planning of townhome communities, the grandeur of commercial ventures, or the strategic provision of land providing, GRACIA's expertise shines through in every endeavor.</p>
          <p>What sets GRACIA apart is not just the breadth of our experience but the depth of our commitment to excellence. With more than seven successful businesses under our belt, we bring a wealth of knowledge to every project, ensuring a seamless fusion of innovation, functionality, and aesthetics.</p>
        </div>
      </div>
    </div>
  )
}

export default About