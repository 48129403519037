import Navbar from '../Components/ContactUs/Navbar/Navbar'
import GetInTouch from '../Components/ContactUs/GetInTouch/GetInTouch'
import VisitOurClinic from '../Components/ContactUs/VisitOurClinic/VisitOurClinic'
import Footer from '../Components/ContactUs/Footer/Footer'

const Contact = () => {
  return (
    <div>
      <Navbar />
      <GetInTouch />
      <VisitOurClinic />
      <Footer />
    </div>
  )
}

export default Contact