import './International.css'

const International = () => {
    return (
        <div>
            <div className="international">
                <div className="international-top">
                    <div className='international-top-img'>
                        <img src="./GraciaDowntownPhotos/International/1.png" alt="" />
                    </div>
                    <div className='international-top-content'>
                        <h3>Swiss International Partnership</h3>
                    </div>
                </div>
                <div className="international-bottom">
                    <p>
                        Gracia Downtown is more than just an address. It's a distinguished community crafted for those who appreciate the finer things in life. The defining element of Gracia Downtown lies in its exclusive collaboration with Swiss International, a globally renowned hospitality brand with impeccable service and unwavering quality. Renowned for their philosophy of "International Quality - Local Affinity," Swiss International brings their expertise to create a living experience unparalleled at Gracia Downtown, Lahore. Exceptional service and deep understanding of resident’s needs is the essence of the Swiss International experience, and it becomes your lifestyle at Gracia Downtown Lahore.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default International