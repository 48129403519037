import "./OurPartners.css";

const OurPartners = () => {
  return (
    <div>
      <div className="ourpartners">
        <div className="ourpartners-heading">
          <h3>OUR national PARTNERS</h3>
          <hr />
        </div>
        <div className="ourpartners-img">
          <img src="./HomePhotos/OurPartners/ourpartners1.png" alt="partners" />
          <img src="./HomePhotos/OurPartners/ourpartners2.png" alt="partners" style={{ width: '170px' }} />
          <img src="./HomePhotos/OurPartners/ourpartners3.png" alt="partners" />
          <img src="./HomePhotos/OurPartners/ourpartners4.jpeg" alt="partners" />
          <img src="./HomePhotos/OurPartners/ourpartners5.jpeg" alt="partners" style={{ width: '170px' }} />
          <img src="./HomePhotos/OurPartners/ourpartners6.png" alt="partners" style={{ width: '170px' }} />
          <img src="./HomePhotos/OurPartners/ourpartners7.png" alt="partners" style={{ width: '170px' }} />
          <img src="./HomePhotos/OurPartners/ourpartners8.png" alt="partners" style={{ width: '170px' }} />

        </div>
      </div>
    </div>
  );
};

export default OurPartners;








// Its part but hide
// <div className="discover-park">
//   <h3>DISCOVER <br />THE PARK RESIDENCES</h3>
//   <div className="discover-park-content">
//     <div className="discover-park-top">
//       <div>
//         <img src="./AboutPhotos/OurCertifications/1.png" alt="" />
//         <p>GYM</p>
//       </div>
//       <div>
//         <img src="./AboutPhotos/OurCertifications/2.png" alt="" />
//         <p>COFFEE SHOP</p>
//       </div>
//       <div>
//         <img src="./AboutPhotos/OurCertifications/3.png" alt="" />
//         <p>SAUNA</p>
//       </div>
//       <div>
//         <img src="./AboutPhotos/OurCertifications/4.png" alt="" />
//         <p>24/7 ELECTRICTY</p>
//       </div>
//     </div>
//     <div className="discover-park-bottom">
//       <div>
//         <img src="./AboutPhotos/OurCertifications/5.png" alt="" />
//         <p>PARK ON ALL SIDES</p>
//       </div>
//       <div>
//         <img src="./AboutPhotos/OurCertifications/6.png" alt="" style={{ width: '60px' }} />
//         <p>ELEVATOR</p>
//       </div>
//       <div>
//         <img src="./AboutPhotos/OurCertifications/7.png" alt="" />
//         <p>SMART APPARTMENTS</p>
//       </div>
//     </div>
//   </div>
// </div>