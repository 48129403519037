import './Gallery.css'
import DowntownEvent from './DowntownEvent/DowntownEvent'
import OfficeVisit from './OfficeVisit/OfficeVisit'
import SiteVisit from './SiteVisit/SiteVisit'
import PartnerContract from './PartnerContract/PartnerContract'

const Gallery = () => {
  return (
    <div>
      <div className='g-downtownevent'>
        <h2>GRACIA DOWNTOWN GRAND REVEAL EVENT</h2>
        <DowntownEvent />
      </div>
      <div className='g-officevisit'>
        <h2>OFFICE VISIT</h2>
        <OfficeVisit />
      </div><div className='g-sitevisit'>
        <h2>SITE VISIT</h2>
        <SiteVisit />
      </div><div className='g-partnercontract'>
        <h2>Partenership contract</h2>
        <PartnerContract />
      </div>
    </div>
  )
}

export default Gallery










































// import './Gallery.css';
// import React, { useState, useEffect } from 'react';
// import PartnerContract from './PartnerContract/PartnerContract';
// import SiteVisit from './SiteVisit/SiteVisit';
// import OfficeVisit from './OfficeVisit/OfficeVisit';
// import DowntownEvent from './DowntownEvent/DowntownEvent';

// const Gallery = () => {
//   const [selectedComponent, setSelectedComponent] = useState('DowntownEvent');

//   const renderComponent = () => {
//     switch (selectedComponent) {
//       case 'PartnerContract':
//         return <PartnerContract />;
//       case 'SiteVisit':
//         return <SiteVisit />;
//       case 'OfficeVisit':
//         return <OfficeVisit />;
//       case 'DowntownEvent':
//         return <DowntownEvent />;
//       default:
//         return null;
//     }
//   };

//   useEffect(() => {
//     // Any initial setup can go here if needed
//   }, []);

//   return (
//     <div>
//       <div className='gallery'>
//         <div className='gallery-heading'><h3>Our Events</h3></div>
//         <div>
//           <nav>
//             <ul className='gallery-links'>
//               <li>
//                 <button className={selectedComponent === 'PartnerContract' ? 'selected' : ''} onClick={() => setSelectedComponent('PartnerContract')}>Partnership Contract</button>
//               </li>
//               <li>
//                 <button className={selectedComponent === 'SiteVisit' ? 'selected' : ''} onClick={() => setSelectedComponent('SiteVisit')}>Site Visit</button>
//               </li>
//               <li>
//                 <button className={selectedComponent === 'OfficeVisit' ? 'selected' : ''} onClick={() => setSelectedComponent('OfficeVisit')}>Office Visit</button>
//               </li>
//               <li>
//                 <button className={selectedComponent === 'DowntownEvent' ? 'selected' : ''} onClick={() => setSelectedComponent('DowntownEvent')}>Downtown Event</button>
//               </li>
//             </ul>
//           </nav>
//           <div>
//             {renderComponent()}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Gallery;
