import './VisitOurClinic.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

const VisitOurClinic = () => {
    return (

        <div>
            <div className='visitourclinic'>
                <div className='visitourclinic-top'>
                    <div className='visitourclinic-heading'>
                        <h3>VISIT US</h3>
                        <hr />
                    </div>
                    <h4>Opening Hours</h4>
                    <p>Come and visit us for an amazing experience</p>
                </div>
                <div className='visitourclinic-bottom'>
                    <div className='visitourclinic-bottom-days'>
                        <p>Monday - Saturday</p>
                        <div className='visitourclinic-bottom-timing'>
                            <FontAwesomeIcon icon={faClock} className='visitourclinic-bottom-timing-clock'/>
                            <p>11.00 PM - 07.00 PM</p>
                        </div>
                    </div>
                    <div className='visitourclinic-bottom-day'>
                        <p>Sunday</p>
                        <div className='visitourclinic-bottom-holiday'>
                            <FontAwesomeIcon icon={faClock} />
                            <p>Closed</p>
                        </div>
                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2752.119694482084!2d74.17663624723231!3d31.389800177891736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3918ffa85b28bc51%3A0xa30d66a6ca0eca0d!2sBahria%20Springs%20Park!5e1!3m2!1sen!2s!4v1710742404799!5m2!1sen!2s"
                title="unique"
                width="100%"
                height="450"
                style={{ border: "0", marginTop: '10vw' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    )
}

export default VisitOurClinic