import './OurTeam.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurTeam = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  return (
    <div className="ourteam">
    <div className='ourteam-head'>
           <div className='ourteam-heading'>
             <h2>meet our team</h2>
             <hr />
           </div>
           
         </div>
      <div className="slider-container m-10">
        <Slider {...settings}>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/1.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>Major General Nasrullah Tahir Dogar (retd)</h4>
              <p>( chairman )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/2.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>Mahmood ul Haq</h4>
              <p>( DIRECTOR )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/3.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>Muhammad Ehsan Dogar</h4>
              <p>( DIRECTOR )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/4.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>arslan ahmad</h4>
              <p>( ARCHITECT )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/5.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>sadam hussain</h4>
              <p>( ARCHITECT )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/6.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>TALHA IMTIAZ</h4>
              <p>( ARCHITECT )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/7.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>umna nabi</h4>
              <p>( HR )</p>
            </div>
          </div>
          <div className='ourteam-bottom-card'>
            <div className='ourteam-bottom-card-img'>
              <img src="./HomePhotos/OurTeam/8.png" alt="cards" />
            </div>
            <div className='ourteam-bottom-card-data'>
              <h4>salman ashraf</h4>
              <p>( Engineer )</p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default OurTeam























// <div className="ourteam">

//         <div className='ourteam-head'>
//           <div className='ourteam-heading'>
//             <h2>meet our team</h2>
//             <hr />
//           </div>
//           <h4>
//             The Visionary Behind this Ground Breaking Project
//           </h4>
//         </div>
//         <div className='ourteam-bottom'>
//           <div className='ourteam-bottom-card'>
//             <div className='ourteam-bottom-card-img'>
//               <img src="./HomePhotos/OurTeam/1.png" alt="cards" />
//             </div>
//             <div className='ourteam-bottom-card-data'>
//               <h4>Major General Nasrullah Tahir Dogar (retd)</h4>
//               <p>( chairman )</p>
//             </div>
//           </div>
//           <div className='ourteam-bottom-card'>
//             <div className='ourteam-bottom-card-img'>
//               <img src="./HomePhotos/OurTeam/2.png" alt="cards" />
//             </div>
//             <div className='ourteam-bottom-card-data'>
//               <h4>Mahmood ul Haq</h4>
//               <p>( DIRECTOR )</p>
//             </div>
//           </div>
//           <div className='ourteam-bottom-card'>
//             <div className='ourteam-bottom-card-img'>
//               <img src="./HomePhotos/OurTeam/3.png" alt="cards" />
//             </div>
//             <div className='ourteam-bottom-card-data'>
//               <h4>Muhammad Ehsan Dogar</h4>
//               <p>( DIRECTOR )</p>
//             </div>
//           </div>
//         </div>

//       </div>