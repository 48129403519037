import './DowntownEvent.css'
import ReactPlayer from 'react-player/lazy'

const DowntownEvent = () => {
    return (
        <div>

            <div className='downtownevent'>
                <div className='downtownevent-img'>
                    <div className='downtownevent-1img'>
                        <img src="./EventsPhotos/Gallery/downtown1.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown2.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown3.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown6.jpg" alt="" />
                    </div>
                    <div className='downtownevent-2img'>
                        <img src="./EventsPhotos/Gallery/downtown4.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown5.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown7.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown8.jpg" alt="" />
                        <img src="./EventsPhotos/Gallery/downtown9.jpg" alt="" />
                    </div>
                    <img src="./EventsPhotos/Gallery/downtown7.jpg" alt="" className='downtownevent-seprtimg'/>
                </div>
            </div>
            <div className='downtownevent-vid'>
                <ReactPlayer url='./EventsPhotos/Gallery/downtown.mp4' controls='true' />
            </div>
        </div>
    )
}

export default DowntownEvent