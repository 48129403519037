import React from "react";
import "./Parkresidency.css";
// import { Link } from "react-router-dom";

const Parkresidency = () => {
  return (
    <div>
      <div className="parkresidency">
        <div className="parkresidency-top">
          <img src="./GraciaDowntownPhotos/ParkResidency/parkresidency.png" alt="building" />
        </div>
        <div className="gdparkresidency-bottom">
          <h3>WELCOME TO THE <br /> GRACIA DOWNTOWN</h3>
          <p>
          Gracia Downtown, a landmark development in the heart of Bahria Town Lahore. This multi-storied apartment building redefines luxury living while offering the highest rental return and the best ROI in Lahore. As the first-ever Swiss International-managed residential project in Bahria Town Lahore, it offers guaranteed rental returns. This, combined with the prestige of the Swiss International name, ensures the value of your investment soars. It's a haven for those who seek a life beyond luxury, where lifestyle Blooms and Investment Booms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Parkresidency;

// <Link to="/About">READ MORE</Link>