import React from 'react'
import './InternationalPartners.css'

const InternationalPartners = () => {
    return (
        <div>
            <div className='home-interpartners'>
                <div className="home-interpartners-heading">
                    <h3>OUR international PARTNERS</h3>
                    <hr />
                </div>
                <div className="home-interpartners-img">
                    <img src="./AboutPhotos/Partners/internationparterns.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default InternationalPartners