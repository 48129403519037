import "./AboutHeader.css"
import { NavLink } from 'react-router-dom'

const AboutHeader = () => {
  return (
    <div>
      <div className='about-header'>
        <div className='about-header-content'>
          <h2>where comfort meets sophistication</h2>
          <p>Where every milestone is a step towards a luxury living.</p>
        </div>
        <div className='about-header-img'>
        <NavLink to='/GraciaDowntown'><img src="./AboutPhotos/AboutHeader/2.jpg" alt="" /></NavLink>
        <NavLink to='/GraciaPark'><img src="./AboutPhotos/AboutHeader/3.jpg" alt="" className='about-header-3img' /></NavLink>
        <NavLink to='/GraciaOrchird'><img src="./AboutPhotos/AboutHeader/4.jpg" alt="" /></NavLink>
        </div>
      </div>
    </div>
  )
}

export default AboutHeader