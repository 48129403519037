import React from "react";
import './Surrounding.css'

const Surrounding = () => {
  return (
    <div>
      <div className="gdsurrounding">
        <h3>SURROUNDINGS</h3>
        <video src="./GraciaDowntownPhotos/Surrounding/surroundings.mp4" controls></video>
      </div>
      <div className="gdlocation">
        <h3>LOCATION</h3>
        <div className="location-map">
          <iframe
            title="unique"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3119.2863450810837!2d74.16827813880732!3d31.33970385157352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190564309348d9%3A0x793970a1917c191c!2sSwiss%20International%20Residencies%20by%20GRACIA!5e1!3m2!1sen!2s!4v1719826235609!5m2!1sen!2s"
            width="100%"
            height="300"
            style={{ border: "0", marginTop: "35px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
