import React from "react";
import './Surrounding.css'

const Surrounding = () => {
  return (
    <div>
      <div className="surrounding">
        <h3>SURROUNDINGS</h3>
        <video src="./GraciaParkPhotos/Surrounding/surrounding.mp4" controls></video>
      </div>
      <div className="location">
        <h3>LOCATION</h3>
        <div className="location-map">
          <iframe
            title="unique"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2752.119694482084!2d74.17663624723231!3d31.389800177891736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3918ffa85b28bc51%3A0xa30d66a6ca0eca0d!2sBahria%20Springs%20Park!5e1!3m2!1sen!2s!4v1710742404799!5m2!1sen!2s"
            width="100%"
            height="300"
            style={{ border: "0", marginTop: "35px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
