import React from 'react'
import Navbar from '../Components/GraciaPark/Navbar/Navbar'
import Header from "../Components/GraciaPark/GPHeader/GPHeader"
import Parkresidency from '../Components/GraciaPark/ParkResidency/Parkresidency'
import PremiumResidences from "../Components/GraciaPark/PremiumResidences/Premiumresidences"
import GPResidencePlan from "../Components/GraciaPark/GPResidencePlan/GPResidencePlan"
import Services from '../Components/GraciaPark/Services/Services'
import Amenities from '../Components/GraciaPark/Amenities/Amenities'
import Surrounding from '../Components/GraciaPark/Surrounding/Surrounding'
import Footer from '../Components/GraciaPark/Footer/Footer'



const GraciaPark = () => {
  return (
    <div>
        <Navbar />
        <Header />
        <Parkresidency />
        <PremiumResidences />
        <GPResidencePlan />
        <Services />
        <Amenities />
        <Surrounding /> 
        <Footer /> 

        </div>
  )
}

export default GraciaPark